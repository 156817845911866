<script setup lang="ts">
const route = useRoute()

useHead(() => ({
  htmlAttrs: {
    lang: 'nl',
    class: 'h-full bg-white',
  },
  link: [
    {
      rel: 'canonical',
      href: `https://ikwoonbeter.nl${route.path}`,
    },
  ],
  templateParams: {
    site: {
      name: 'ikwoonbeter',
    },
    separator: '-',
    subPage: null,
  },
  titleTemplate: '%s %separator %separator %site.name',
  bodyAttrs: { class: 'h-full' },
}))
</script>

<template>
  <div class="text-neutral/70 antialiased">
    <BaseDevbar />
    <NuxtPage />
    <BaseNotificationList />
  </div>
</template>
